<template>
  <div class="memberConsHistoryView">
    <div class="tView">
      <label style="flex: 1.2">流水号</label>
      <label>业务类型</label>
      <!-- <label>联系电话</label> -->
      <label>姓名</label>
      <label>卡名称</label>
      <label>卡金额</label>
      <label>实收金额</label>
      <label>支付方式</label>
      <label>支付时间</label>
      <label>当前状态</label>
      <label>操作门店</label>
<!--      <label>操作</label>-->
    </div>
    <div class="listView" style="height: 500px;" v-loading="loading" element-loading-text="加载中...">
      <div class="listItem" v-for="item in tableData" :key="item.id">
        <label style="flex: 1.2">{{ item.id }}</label>
        <label>{{ item.businessTypeName }}</label>
        <!-- <label>{{ item.phone }}</label> -->
        <label>{{ item.realName }}</label>
        <label>{{ item.cardName }}</label>
        <label>￥{{ item.cardPrice }}</label>
        <label>￥{{ item.payPrice }}</label>
        <label>{{ item.payWayStr }}</label>
        <label >{{ item.payTime }}</label>
        <label>{{ item.payStateStr }}</label>
        <label>{{ item.storeName }}</label>
<!--        <label @click="handlerDetailView">详情</label>-->
      </div>
    </div>
     <div style="margin-top:15px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    <!--衣物详情-->
    <pop-view id="recharge-detailViewPopView" :show="detailView" width="600px" title="充值详情"
              @cancel="detailView = false">
      <div class="contentView">
        <div class="cashContentView">
          <div class="headerView flexView">
            <label>项目</label>
            <label>数量</label>
            <label>支付</label>
            <label>服务人员</label>
          </div>
          <div class="flexView">
            <label>项目</label>
            <label>数量</label>
            <label>支付</label>
            <label>服务人员</label>
          </div>
        </div>
      </div>
    </pop-view>

  </div>
</template>

<script>
import {getCardRecordList} from '@/api/member/user';
import PopView from '@/components/PopView'

export default {
  name: "rechargeRecord",
  components: {
    PopView
  },
  props: {
    userId: {type: String},
  },
  data() {
    return {
      tableData: [],
      recordList: {
        timeStart: new Date(),
        timeEnd: new Date(),
        query: '',
        channel: 1
      },
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量,
      loading: false,
      detailView: false
    }
  },
  created() {
    this.getCardRecordList()
  },
  methods: {
    //初始化办卡/充值记录列表
    getCardRecordList: function () {
      this.loading = true
      const params = new URLSearchParams()
      params.append('userId', this.userId)
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      params.append('query', this.recordList.query)
      params.append('channel', this.recordList.channel)
      getCardRecordList(params).then(({data}) => {
         if (data.code==0) {
        this.tableData = data.data.userRecordList.records
        this.total= data.data.userRecordList.total
      }
        this.loading = false
      })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getCardRecordList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getCardRecordList();
    },
    handlerDetailView() {
      this.detailView = true
    }
  }
}
</script>

<style scoped lang="scss">

.memberConsHistoryView {
  text-align: center;

  > .tView {
    display: flex;
    line-height: 50px;
    font-size: 14px;
    background: #fff;

    > label {
      flex: 1;
    }

    //> label:nth-child(2) {
    //    flex: 2;
    //}
  }

  > .listView {
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    // padding-bottom: 25px;

    > .listItem {
      display: flex;
      background: #fff;
      line-height: 20px;
      padding: 15px 0;
      color: #333;
      font-size: 14px;

      > label {
        flex: 1;
      }

      //> label:nth-child(2) {
      //    flex: 2;
      //}
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }
}

#recharge-detailViewPopView > .popView-contentView .contentView > div {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
}

</style>
