<template>
  <div
    class="memberBaseInfoView listView"
    v-loading="loading"
    element-loading-text="加载中..."
  >
    <div class="memberInfoView">
      <div>
        <label>姓名</label>
        <input v-model="userDetail.realName" :disabled="!update" />
      </div>
      <div style="justify-content: space-between;">
        <label>联系方式</label>
        <label v-if="!update" style="color: rgb(51, 51, 51);">{{
          userDetail.passPhone
        }}</label>
        <label v-else style="color: rgb(51, 51, 51);">{{
          userDetail.phone
        }}</label>
        <!-- <input :disabled="true"  type="number" v-model="userDetail.phone" /> -->
      </div>
      <div class="sexView" style="justify-content: space-between;" >
        <label>性别</label>
        <label v-if="!update">{{userDetail.gender == 1 ? '男' : '女'}}</label>
        <!-- <input
          v-if="!update"
          :disabled="!update"
          v-model=""
        /> -->
        <el-select v-else v-model="userDetail.gender" placeholder="请选择性别">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="birthdayView">
        <label>生日</label>
        <input
          v-if="!update"
          :disabled="!update"
          v-model="userDetail.birthday"
        />
        <el-date-picker
          v-else
          v-model="userDetail.birthday"
          type="date"
          class="picker-date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="accInfoView">
      <div>
        <label>订单总数(单)</label>
        <label style="color: rgb(51, 51, 51);">{{
          userDetail.totalOrderNum
        }}</label>
      </div>
      <div>
        <label>消费总额(元)</label>
        <label style="color: rgb(51, 51, 51);">{{
          userDetail.totalConsumption
        }}</label>
      </div>
      <div>
        <label>均单金额(元)</label>
        <label style="color: rgb(51, 51, 51);">{{
          userDetail.avgOrderPrice
        }}</label>
      </div>
    </div>
    <div style=" margin: 10px 0">权益会员信息</div>
    <div class="cardInfoView">
      <div>
        <label>是否权益会员</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.member">{{
          userDetail.member ? "是" : "否"
        }}</label>
        <label style="color: rgb(51, 51, 51);" v-else>--</label>
      </div>
      <div>
        <label>会员卡号</label>
        <label
          v-if="userDetail.memberUser.cardNo"
          style="color: rgb(51, 51, 51);"
          >{{
            update == false
              ? userDetail.memberUser.passCardNo
              : userDetail.memberUser.cardNo
          }}</label
        >
        <label v-else style="color: rgb(51, 51, 51);">--</label>
        <!-- <label style="color: rgb(51, 51, 51);" v-if="userDetail.memberUser">{{ userDetail.memberUser.cardNo }}</label> -->
        <!-- <input v-if="userDetail.memberUser" v-model="userDetail.memberUser.cardNo" :disabled="true" style="flex: 1;text-align: right;border: none; outline: none;background-color: #f8f8f8;"></input>
		<label style="color: rgb(51, 51, 51);" v-else>--</label> -->
        <!--  -->
      </div>
      <div>
        <label>开卡日期</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.applyCard">{{
          userDetail.applyCard
        }}</label>
        <label style="color: rgb(51, 51, 51);" v-else>--</label>
      </div>
      <div>
        <label>开卡门店</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="userDetail.applyCardStore"
          >{{ userDetail.applyCardStore }}</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>--</label>
      </div>
      <div>
        <label>续卡门店</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="userDetail.renewCardStore"
          >{{ userDetail.renewCardStore }}</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>--</label>
      </div>
      <div>
        <label>到期日期</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.valideTime">{{
          userDetail.valideTime
        }}</label>
        <label style="color: rgb(51, 51, 51);" v-else-if="userDetail.applyCard"
          >已过期</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>--</label>
      </div>
      <div>
        <label>首次消费</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.firstConsume">{{
          userDetail.firstConsume
        }}</label>
        <label style="color: rgb(51, 51, 51);" v-else>未消费</label>
      </div>
      <div>
        <label>末次消费</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.lastConsume">{{
          userDetail.lastConsume
        }}</label>
        <label style="color: rgb(51, 51, 51);" v-else>未消费</label>
      </div>
      <div>
        <label>权益优惠</label>
        <label style="color: rgb(51, 51, 51);" v-if="this.userDetail.memberUser"
          >{{ userDetail.memberUser.totalDiscountAmount }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>未消费</label>
      </div>
    </div>
    <div class="accInfoView">
      <!-- <div>
        <label>储值本金</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.capital"
          >{{ userDetail.capital }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div> -->
      <!-- <div>
        <label>赠送金额</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.give"
          >{{ userDetail.give }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div> -->
      <!-- <div>
        <label>充值次数</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.rechargeTimes"
          >{{ userDetail.rechargeTimes }}次</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div> -->
    </div>
    <div style=" margin: 10px 0">通享卡充值余额信息</div>
    <div class="accInfoView">
      <div>
        <label>通享卡总充值余额</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="userDetail.totalUniversalBalance"
          >{{ userDetail.totalUniversalBalance }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
      <div>
        <label>本店通享卡余额</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="userDetail.storeUniversalBalance"
          >{{ userDetail.storeUniversalBalance }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
      <div>
        <label>其他店通享卡余额</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="userDetail.otherStoreUniversalBalance"
          >{{ userDetail.otherStoreUniversalBalance }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
    </div>
    <div style=" margin: 10px 0">历史充值余额信息</div>
    <div class="accInfoView">
      <div>
        <label>本店历史充值总余额</label>
        <label
          style="color: rgb(51, 51, 51);"
          v-if="this.userDetail.historyTotalBalance"
          >{{ userDetail.historyTotalBalance }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
      <div>
        <label>本店历史充值余额（本金）</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.capital"
          >{{ userDetail.capital }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
      <div>
        <label>本店历史充值余额（赠额）</label>
        <label style="color: rgb(51, 51, 51);" v-if="userDetail.give"
          >{{ userDetail.give }}元</label
        >
        <label style="color: rgb(51, 51, 51);" v-else>0</label>
      </div>
    </div>
    <button class="btn-save" @click="handleUpdate">{{ bthValue }}</button>
  </div>
</template>

<script>
import {
  getUserDetailById,
  updateUser,
  getDetailCheck,
} from "@/api/member/user";

export default {
  name: "memberBaseInfo",
  props: {
    userId: { type: String },
  },
  data() {
    return {
      userDetail: {
        userId: "",
        userType: 1,
        phone: "",
        birthday: "",
        realName: "",
        userName: "",
        avator: "",
        email: "",
        score: 0,
        totalScore: 0,
        state: 0,
        consumptionFrequency: 0,
        userQuality: 0,
        createTime: "",
        memberUser: {
          cardNo: "",
          balance: 0,
        },
        totalOrderNum: 0,
        totalConsumption: 0,
        avgOrderPrice: 0,
        recordList: [],
        orderList: [],
      },
      loading: false,
      update: false,
      bthValue: "编辑",
      options: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
    };
  },
  created() {
    this.getUserDetailById();
  },
  methods: {
    //获取用户详情数据
    getUserDetailById: function() {
      this.loading = true;
      getUserDetailById(this.userId).then(({ data }) => {
        this.userDetail = data.data;
        this.userDetail.passPhone =
          this.userDetail.phone.slice(0, 3) +
          "****" +
          this.userDetail.phone.slice(7);
        if (this.userDetail.memberUser && this.userDetail.memberUser.cardNo) {
          this.userDetail.memberUser["passCardNo"] =
            this.userDetail.memberUser.cardNo.slice(0, 3) +
            "****" +
            this.userDetail.memberUser.cardNo.slice(7);
        }

        if (!this.userDetail.memberUser) {
          this.userDetail.memberUser = {
            cardNo: "",
            balance: 0,
          };
        }
        this.loading = false;
      });
    },
    // 处理修改用户信息
    handleUpdate() {
      // 编辑
      if (!this.update) {
        getDetailCheck().then(({ data }) => {
          if (data.data) {
            this.update = true;
            this.$message.success("进入编辑模式");
            this.bthValue = "保存";
          } else {
            this.$message.error("每天只可以编辑10次用户信息");
          }
        });
      } else {
        // 调用接口进行保存
        // if (this.userDetail.memberUser) {
        //   if (this.userDetail.memberUser.cardNo) {
        //     params.append('cardNo', this.tableData.memberUser.cardNo)
        //   }
        // }
        let data = {
          userId: this.userDetail.userId,
          phone: this.userDetail.phone,
          gender: this.userDetail.gender,
          birthday: this.userDetail.birthday,
          realName: this.userDetail.realName,
          cardNo: this.userDetail.memberUser.cardNo,
        };
        updateUser(data).then((response) => {
          if (response.data.data === true) {
            this.$message.success("修改成功");
            this.update = false;
            this.bthValue = "修改";
            this.getUserDetailById();
          } else {
            this.$message.error(response.data.msg);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.memberBaseInfoView {
  position: relative;
  height: 100%;
  padding-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;

  > .memberInfoView {
    display: flex;

    > div {
      width: 215px;
      /*height: 48px;*/
      margin-right: 10px;
      background: #f8f8f8;
      border-radius: 6px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      display: flex;
      overflow: hidden;

      > label {
        color: #999;
        margin-right: 10px;
      }

      > input {
        flex: 1;
        background: #f8f8f8;
        border: none;
        outline: none;
        text-align: right;
        font-size: 14px;
        color: #333;
        height: 24px;
        min-width: 0;
        padding: 0;
      }

      > input[disabled] {
        color: #333;
        -webkit-text-fill-color: #333;
        opacity: 1;
      }
    }

    > div:last-child {
      margin-right: 0;
    }

    > .sexView .el-select {
      flex: 1;
      vertical-align: top;
      height: 24px;
      padding-right: 20px;
    }

    ::v-deep > .sexView .el-select input {
      border: none;
      text-align: right;
      padding-right: 0;
      font-size: 14px;
      color: #333;
      line-height: 24px;
      margin-top: -10px;
      background: #f8f8f8;
      cursor: pointer;
    }

    ::v-deep > .sexView .el-select .el-input__suffix {
      right: -25px;
    }

    > .birthdayView .picker-date {
      flex: 1;
      vertical-align: top;
      height: 24px;
    }

    ::v-deep > .birthdayView .picker-date input {
      border: none;
      text-align: right;
      font-size: 14px;
      color: #333;
      padding: 0;
      line-height: 24px;
      height: 24px;
      background: #f8f8f8;
    }

    ::v-deep > .birthdayView .picker-date > .el-input__prefix {
      display: none;
    }

    ::v-deep > .birthdayView .picker-date > .el-input__suffix {
      display: none;
    }
  }

  > .cardInfoView {
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      /*height: 48px;*/
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .accInfoView {
    margin-top: 18px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      /*height: 48px;*/
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .btn-save {
    position: absolute;
    right: 30px;
    top: 26px;
    width: 90px;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    background: #3370ff;
    padding: 0;
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
