<template>
  <div class="memberConsHistoryView">
    <div class="tView">
      <label>流水号</label>
      <label>优惠券名称</label>
      <label>获得方式</label>
      <label>门店</label>
      <label>状态</label>
      <label>领取时间</label>
      <label>开始日期</label>
      <label>到期时间</label>
      <label>过期原因</label>
      <label>使用时间</label>
    </div>
    <div class="listView" style="height: calc(100vh - 200px);" v-loading="loading" element-loading-text="加载中...">
      <div class="listItem" v-for="item in recordList" :key="item.id">
        <label>{{ item.id }}</label>
        <label>{{ item.couponName }}</label>
        <label>{{ item.getWayName }}</label>
        <label>{{ item.storeName }}</label>
        <label>
          <el-tag v-if="item.stateDesc === '正常'">{{ item.stateDesc }}</el-tag>
          <el-tag type="danger" v-else>{{ item.stateDesc }}</el-tag>
        </label>
        <label>{{ item.createTime }}</label>
        <label>{{ item.beginTime }}</label>
        <label>{{ item.expireTime }}</label>
        <label>{{ item.expireReason?item.expireReason:'--' }}</label>
        <label>{{ item.useTime }}</label>
      </div>
    </div>


  </div>
</template>

<script>
import {getCouponUserByUserId} from '@/api/member/coupon';

export default {
  name: "rechargeRecord",
  props: {
    userId: {type: String},
  },
  data() {
    return {
      recordList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0, // 总数量,
      loading: false,
    }
  },
  created() {
    this.getCouponUserRecordList()
  },
  methods: {
    //初始化办卡/充值记录列表
    getCouponUserRecordList: function () {
      this.loading = true
      getCouponUserByUserId(this.userId).then(response => {
        this.recordList = response.data.data
        this.loading = false
      })
    },
  }
}
</script>

<style scoped lang="scss">

.memberConsHistoryView {
  text-align: center;

  > .tView {
    display: flex;
    line-height: 50px;
    font-size: 14px;
    background: #fff;

    > label {
      flex: 1;
    }

    > label:nth-child(2) {
      flex: 2;
    }
  }

  > .listView {
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 25px;

    > .listItem {
      display: flex;
      background: #fff;
      line-height: 20px;
      padding: 15px 0;
      color: #333;
      font-size: 14px;

      > label {
        flex: 1;
      }

      > label:nth-child(2) {
        flex: 2;
      }
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }
}

</style>
