<template>
  <div class="transfer-record" >
    <Table
      :tableData="tableData"
      :titleList="titleList"
      :orientation="'center'"
      :height="'calc(100vh - 200px)'"
      :total="total"
      :headerStyle="headerStyle"
      :page="false"
      @handleCurrentChange="handleCurrentChange"
      :loading="transferLoading"
    />
  </div>
</template>

<script>
import Table from "@/components/table2";
import { getTransferBalanceRecord } from "@/api/member/user";
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    Table,
  },
  data() {
    return {
      searchData: {
        current: 1,
        size: 10,
      },
      tableData: [],
      titleList: [
        {
          label: "流水号",
          prop: "id",
        },
        {
          label: "客户名称",
          prop: "userName",
        },
        {
          label: "历史余额",
          prop: "historyBalance",
        },
        {
          label: "转至账户",
          prop: "toAccount",
        },
        {
          label: "操作时间",
          prop: "createTime",
        },
        {
          label: "结果",
          prop: "result",
        },
        {
          label: "操作门店",
          prop: "storeName",
        },
        {
          label: "操作人",
          prop: "operateUser",
        },
      ],
      headerStyle: {
        fontWeight: 400,
      },
      total: 0,
      transferLoading: false,
    };
  },
  created(){
    this.getTransferBalanceRecordList()
  },
  methods: {
    // 获取用户转账记录
    getTransferBalanceRecordList() {
      this.transferLoading=true
      getTransferBalanceRecord({...this.searchData,userId:this.userId}).then(({ data }) => {
        if (data.code==0) {
          this.tableData=data.data.records
          this.total=data.data.total
           this.transferLoading=false
        }else{
          this.tableData=[]
           this.transferLoading=false
        }
      }).catch(()=>{
        this.tableData=[]
         this.transferLoading=false
      })
    },
    handleCurrentChange(value) {
      this.searchData.current=value
      this.getTransferBalanceRecordList()
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-record {
  
}
</style>
