<template>
  <div class="transaction-container">
    <div class="transaction-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="source" label="订单来源">
        </el-table-column>
          <el-table-column prop="storeName" label="消费门店">
        </el-table-column>
        <el-table-column prop="userName" label="姓名">
        </el-table-column>
      
        <el-table-column prop="orderStatus" label="订单状态">
        </el-table-column>
        <el-table-column prop="payType" label="付款方式">
        </el-table-column>
        <el-table-column prop="price" label="实付金额(¥)">
        </el-table-column>
        <el-table-column prop="orderTime" label="下单时间">
        </el-table-column>
        <!-- <el-table-column prop="address" label="操作">
        </el-table-column> -->
      </el-table>
    </div>
    <div class="transaction-pagination">
      <el-pagination 
      background 
      @size-change="handleSizeChange" 
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {getUserMoneyRecordByUserId} from '@/api/member/user'

export default {
  name: "transactionTable",
  props: {
    userId: {type: String},
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10
    }
  },
  created() {
    this.getUserMoneyRecord()
  },
  methods: {
    //初始化办卡/充值记录列表
    async getUserMoneyRecord() {
      this.loading = true
      const params = new URLSearchParams()
      params.append('userId', this.userId)
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      let res = await getUserMoneyRecordByUserId(params);
      console.log(res);
      if (res.data.code==0) {
        this.tableData = res.data.data.records
        this.total= res.data.data.total
      }
      this.loading = false
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
	  this.getUserMoneyRecord()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
	  this.getUserMoneyRecord()
    }
  },
}
</script>

<style scoped lang="scss">

/deep/ .cell {
  text-align: center;
}

.transaction-table {
  min-height: 200px;
  max-height: 530px;
  overflow: auto;
}

.transaction-pagination {
  // float: right;
  text-align: center;
  margin-top: 15px;
}
</style>
